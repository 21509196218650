import { defineStore } from 'pinia'
import { v1 as uuidv1 } from 'uuid'

export interface Routine {
  name: string
  id: string
}

export const useRoutinesStore = defineStore(
  'routines',
  () => {
    const routines = ref([] as Routine[])

    function getRoutine(id: string): Routine {
      return routines.value.find((r: Routine) => r.id === id) as Routine
    }

    function add(name: string): string {
      const newId = uuidv1()
      routines.value = [...routines.value, { name, id: newId }]
      return newId
    }

    function remove(id: string) {
      routines.value = [...routines.value.filter((r: Routine) => r.id !== id)]
      // TODO: remove exercises associated
    }

    function update(id: string, name: string) {
      if (!name) return
      routines.value = [...routines.value.map((r: Routine) => {
        if (r.id === id) r.name = name
        return r
      })]
    }

    return {
      add,
      getRoutine,
      remove,
      routines,
      update,
    }
  },
  { persist: true },
)
