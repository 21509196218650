<script setup lang="ts">
import { useRoutinesStore } from '../stores/routines'
const { routines, remove } = toRefs(useRoutinesStore())
const router = useRouter()
</script>

<template>
  <div v-if="routines">
    <nav class="flex w-full justify-between mb-10">
      <h1 class="text-3xl">
        Routines
      </h1>
      <router-link class="btn" :to="`/settings`">
        settings
      </router-link>
    </nav>
    <section />
    <section>
      <router-link class="btn mb-8" :to="`/routines/add`" data-testid="add-routine-btn">
        Add routine
      </router-link>
    </section>
    <section>
      <SwipeButton
        v-for="{ name, id } in routines"
        :key="id"
        class="btn w-full block mb-8"
        :to="`/${id}`"
        @edit="router.push(`/${id}/edit/`)"
        @delete="remove(id)"
      >
        {{ name }}
      </SwipeButton>
    </section>
  </div>
</template>

<route lang="yaml">
meta:
  layout: default
</route>
