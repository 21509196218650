<script setup lang="ts">
import { computed, ref } from 'vue'
import { usePointerSwipe } from '@vueuse/core'
const router = useRouter()
const props = defineProps<{
  to?: string
}>()

const target = ref<Element | null>(null)
const container = ref<HTMLElement | null>(null)

const containerWidth = computed(() => container.value?.offsetWidth)

const right = ref('0')
const canClick = ref(true)
const isOpen = ref(true)

const { distanceX, isSwiping } = usePointerSwipe(target, {
  onSwipe(e: PointerEvent) {
    if (isOpen.value) {
      isOpen.value = false
      canClick.value = false
      right.value = '0'
    }
    if (containerWidth.value) {
      if (distanceX.value > 0) {
        canClick.value = false
        const distance = Math.abs(distanceX.value)
        right.value = `${distance}px`
      }
      else {
        right.value = '0'
      }
    }
  },
  onSwipeEnd(e: PointerEvent, direction: string) {
    if (distanceX.value > 0 && containerWidth.value && (Math.abs(distanceX.value) / containerWidth.value) >= 0.2) {
      canClick.value = false
      right.value = '12em'
      isOpen.value = true
    }
    else {
      right.value = '0'
    }
    setTimeout(() => {
      canClick.value = true
    }, 100)
  },
})

function goToRoute() {
  if (canClick.value && props.to)
    router.push(props.to)
}

const emit = defineEmits<{
  (e: 'edit',): void
  (e: 'delete'): void
}>()
</script>

<template>
  <div ref="container" class="!bg-green-700 rounded relative w-full h-16 m-auto flex items-middle justify-end overflow-hidden p-0 m-0">
    <button class="btn bg-red-600 hover:bg-red-600 w-[6em] uppercase m-0 p-0 rounded-none flex items-baseline pt-5 justify-center" @click="emit('delete')">
      <div i="carbon-trash-can dark:carbon-trash-can" />
    </button>
    <button class="btn bg-orange-400 hover:bg-orange-400 w-[6em] uppercase m-0 p-0 rounded-none flex items-baseline pt-5 justify-center" @click="emit('edit')">
      <div i="carbon-edit dark:carbon-edit" />
    </button>
    <div
      ref="target"
      data-testid="swipedragtarget"
      class="absolute w-full h-full top-0 right-0 bg-green-700 flex items-center justify-center"
      :class="{'transition-all duration-50 ease-out': !isSwiping}"
      :style="{right}"
      @click="goToRoute()"
    >
      <p class="flex text-white items-center">
        <slot />
      </p>
    </div>
  </div>
</template>
