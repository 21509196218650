<script setup lang="ts">
const router = useRouter()

router.afterEach((to, from) => {
  // const toDepth = to.path.split('/').length
  // const fromDepth = from.path.split('/').length
  // to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'

  to.meta.transitionName = 'zoom'
})

// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
// they will be rendered correctly in the html results with vite-ssg
useHead({
  title: 'Vitesse',
  meta: [
    { name: 'description', content: 'Opinionated Vite Starter Template' },
  ],
})
</script>

<template>
  <router-view />
</template>
